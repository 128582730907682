import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-bottom: 2rem;
  }

  .question {
    width: 100%;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loaderContainer {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    color: ${props => props.theme.colors.gray};
  }

  .error {
    color: ${props => props.theme.colors.error};
    font-size: 1.4rem;
    text-align: center;
  }
`
