import React, { useContext, useState } from "react"
import * as Yup from "yup"
import { Formik, Field } from "formik"
import axios from "axios"
import { toast } from "react-toastify"

import Root from "./styles"
import logo from "assets/inova.svg"
import { Button } from "components/Button"
import { LoginTextInput } from "components/LoginTextInput"
import { validationMessages } from "utils/messages"
import { LoginStore } from "store"
import { LOGIN } from "store/reducers/loginReducer"

const validationSchema = Yup.object().shape({
  username: Yup.string().required(validationMessages.required),
  password: Yup.string().required(validationMessages.required)
})

export default props => {
  const [context, dispatch] = useContext(LoginStore)
  const [loading, setLoading] = useState(false)

  const initialValues = {
    username: "",
    password: ""
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/login`,
        headers: { "Content-Type": "application/json" },
        data: { username: context.username, password: context.password }
      })

      if (res) {
        dispatch({ type: LOGIN, payload: res.data.token })
        setTimeout(() => props.history.push("/dashboard"), 1000)
      }
    } catch (e) {
      toast.error(e.response.data.message)
      setLoading(false)
    }
  }

  return (
    <Root>
      <div className="container">
        <img src={logo} alt="InovaMind" className="logo" />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={form => {
            return (
              <form onSubmit={form.handleSubmit}>
                <Field name="username" label="Nome de Usuário" component={LoginTextInput} />
                <Field name="password" type="password" label="Senha" component={LoginTextInput} />
                <Button type="submit" loading={loading}>
                  Entrar
                </Button>
              </form>
            )
          }}
        />
      </div>
    </Root>
  )
}
