import React from "react"

import Container from "./styles"
import { Button } from "components/Button"

export default props => {
  const handleRedirect = () => props.history.replace("/")

  return (
    <Container>
      <h1>Alguma coisa deu errado...</h1>

      <p>Nesse momento não conseguimos aprovar seu cadastro.</p>
      <p>Por favor, tente novamente.</p>

      <Button onClick={handleRedirect}>Tentar novamente</Button>
    </Container>
  )
}
