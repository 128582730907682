import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .codeContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;

    input {
      border: 1px solid ${props => props.theme.colors.gray};
      font-size: 2.4rem;
      height: 5rem;
      width: 5rem;
      text-align: center;
      border-radius: 0.4rem;
      -webkit-appearance: none;
      outline: none;
    }
  }

  .error {
    color: ${props => props.theme.colors.error};
    font-size: 1.4rem;
  }
`
