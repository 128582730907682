import styled from "styled-components"

export default styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  .loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    color: ${props => props.theme.colors.gray};
  }
  
`
