import tinycolor from "tinycolor2"
import { createMuiTheme } from "@material-ui/core/styles"

const baseColor = "#cb184b"

export default {
  colors: {
    primary: baseColor,
    light: tinycolor(baseColor)
      .lighten()
      .toString(),
    dark: tinycolor(baseColor)
      .darken()
      .toString(),
    lightGray: "#ededed",
    gray: "#9c9c9c",
    darkGray: "#5c5c5c",
    error: "#e00b0b",
    green: "green",
    yellow: "yellow",
    red: "red"
  },
  text: {
    primary: baseColor,
    contrast: tinycolor(baseColor).isDark() ? "#ffffff" : "#444444"
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px"
  }
}

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: baseColor
    }
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 14
  }
})
