import React, { useContext } from "react"
import { Route } from "react-router-dom"

import Root from "./styles"
import logo from "assets/inova_white.svg"
import { LoginStore } from "store"
import { LOGOUT } from "store/reducers/loginReducer"

export default ({ component: Component, ...rest }) => {
  const [, dispatch] = useContext(LoginStore)

  const handleLogout = matchProps => {
    dispatch({ type: LOGOUT })
    matchProps.history.replace("/login")
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Root>
          <header>
            <p className="title">
              <img src={logo} alt="InovaMind" className="logo" />
              Onboarding
            </p>

            <button type="button" onClick={() => handleLogout(matchProps)}>
              Sair
            </button>
          </header>

          <Component {...matchProps} />
        </Root>
      )}
    />
  )
}
