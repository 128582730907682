import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 100%;

  header {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.text.contrast};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    .logo {
      width: 15rem;
      padding: 2rem;
      margin-right: 2rem;
    }

    .title {
      font-size: 2.4rem;
      display: flex;
      align-items: center;
    }

    button {
      border: 0;
      background-color: transparent;
      outline: none;
      font-size: 1.6rem;
      padding: 2rem;
      cursor: pointer;
      color: ${props => props.theme.text.contrast};

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0.8rem;
      }
    }
  }
`
