import React, { useEffect, useState, useContext } from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import CircularProgress from "@material-ui/core/CircularProgress"

import Container from "./styles"
import { get, post } from "api/api"
import { Store } from "store"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { Button } from "components/Button"
import { verifyTokenRole } from "config/tokenRouter"

export default props => {
  const [{ token }, dispatch] = useContext(Store)
  const [answerIndex, setAnswerIndex] = useState(0)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  async function fetchQuestions() {
    const res = await get({
      url: "/quiz",
      token: token.value
    })

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      setQuestions(res.questions)
    }
  }

  useEffect(() => {
    if (questions.length === 0) fetchQuestions()
  })

  const handleAnswer = e => {
    let newAnswers = answers
    newAnswers[answerIndex] = e.target.value
    setAnswers(newAnswers)
  }

  const handleNext = () => {
    if (!answers[answerIndex]) {
      return setError("Selecione uma resposta")
    }
    setError("")
    setAnswerIndex(answerIndex + 1)
  }

  const handleSubmit = async () => {
    if (!answers[answerIndex]) {
      return setError("Selecione uma resposta")
    }

    setLoading(true)
    const res = await post({
      url: "/quiz",
      token: token.value,
      data: { answers }
    })

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      return verifyTokenRole(res.token, props.history)
    } else {
      setLoading(false)
    }
  }

  return (
    <Container>
      <h1>Quiz</h1>

      {questions.length > 0 ? (
        <div className="question">
          <h4>{questions[answerIndex].title}</h4>
          <RadioGroup onChange={handleAnswer}>
            {questions[answerIndex].answers.map((answer, index) => (
              <FormControlLabel key={index} value={answer} control={<Radio />} label={answer} />
            ))}
          </RadioGroup>

          {error && <div className="error">{error}</div>}

          <div className="buttonContainer">
            {answerIndex + 1 < questions.length ? (
              <Button onClick={handleNext}>Próxima</Button>
            ) : (
              <Button onClick={handleSubmit} loading={loading}>
                Finalizar
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="loaderContainer">
          <CircularProgress className="loader" />
        </div>
      )}
    </Container>
  )
}
