import produce from "immer"

export const SET_TOKEN = "SET_TOKEN"

const tokenReducer = (draft, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      draft["value"] = payload
      return
    default:
      return
  }
}

export default produce(tokenReducer)
