import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import { roles } from "config/roles"

import SecuredRoute from "./SecuredRoute"
import DefaultContainer from "containers/Default"
import SignedInContainer from "containers/SignedIn"
import LivenessContainer from "containers/Liveness"

import Home from "pages/Home"
import Terms from "pages/Terms"
import Confirmation from "pages/Confirmation"
import Address from "pages/Address"
import DocFront from "pages/DocFront"
import DocBack from "pages/DocBack"
import Proof from "pages/Proof"
import Success from "pages/Success"
import Liveness from "pages/Liveness"
import Quiz from "pages/Quiz"
import End from "pages/End"

import AuthRoute from "./AuthRoute"
import Login from "pages/Login"
import Dashboard from "pages/Dashboard"

export default () => {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <div className="content">
            <TransitionGroup component="div">
              <CSSTransition timeout={250} classNames="fade">
                <Switch location={location}>
                  <DefaultContainer exact path="/" component={Home} />
                  <DefaultContainer path="/terms" component={Terms} />

                  <SecuredRoute path="/confirmation" parent={DefaultContainer} child={Confirmation} roles={[roles.CAN_INSERT_SMS_TOKEN]} />
                  <SecuredRoute path="/address" parent={DefaultContainer} child={Address} roles={[roles.CAN_CONSULT_CEP, roles.CAN_SEND_ADDRESS]} />
                  <SecuredRoute path="/docfront" parent={DefaultContainer} child={DocFront} roles={[roles.CAN_SEND_DOC_FRONT]} />
                  <SecuredRoute path="/docback" parent={DefaultContainer} child={DocBack} roles={[roles.CAN_SEND_DOC_BACK]} />
                  <SecuredRoute path="/proof" parent={DefaultContainer} child={Proof} roles={[roles.CAN_SEND_ADDRESS_DOC]} />
                  <SecuredRoute path="/success" parent={DefaultContainer} child={Success} roles={[roles.CAN_COMPLETE]} />
                  <SecuredRoute path="/liveness" parent={LivenessContainer} child={Liveness} roles={[roles.CAN_SEND_FIRST_LIVENESS_FRAME, roles.CAN_SEND_SECOND_LIVENESS_FRAME, roles.CAN_SEND_THIRD_LIVENESS_FRAME]} />
                  <SecuredRoute path="/quiz" parent={DefaultContainer} child={Quiz} roles={[roles.CAN_GET_QUIZ, roles.CAN_ANSWER_QUIZ]} />
                  <DefaultContainer path="/end" component={End} />

                  <Route path="/login" component={Login} />
                  <AuthRoute path="/dashboard" parent={SignedInContainer} child={Dashboard} />

                  <DefaultContainer component={Home} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      />
    </Router>
  )
}

// <CSSTransition key={location.key} in timeout={250} classNames="fade">
