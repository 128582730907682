import styled from "styled-components"

export default styled.div`
  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    h1 {
      color: white;
      font-size: 2.4rem;
    }

    .logo {
      width: 12rem;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
    }

    header {
      position: relative;
      background-color: transparent;
      width: auto;

      .logo {
        width: 12rem;
        padding: 0;
      }
    }

    h1 {
      font-size: 3rem;
      margin: 2rem 0%;
      text-align: center;
    }
  }
`
