import axios from "axios"
import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"

export const get = async ({ url, token, history }) => {
  return await service("GET", url, token, null, history)
}

export const post = async ({ url, token, data, history }) => {
  return await service("POST", url, token, data, history)
}

const service = async (method, url, token, data, history) => {
  if (token) {
    const decoded = jwtDecode(token)
    const current_time = Date.now() / 1000

    if (decoded.exp < current_time) {
      toast.error("Seu acesso expirou.")
      return history.replace("/")
    } else {
      try {
        const res = await axios({
          method: method,
          url: `${process.env.REACT_APP_API_URL}${url}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          data: data ? data : null
        })
        return res.data
      } catch (error) {
        if (error.response) {
          if (error.response.status === 500) {
            toast.error("Erro interno no servidor. Tente novamente mais tarde.")
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error("Houve um erro ao realizar esta ação")
        }
      }
    }
  } else {
    toast.error("Acesso não autorizado!")
  }
}

export const postFile = async ({ url, token, data, history }) => {
  return await serviceFile("POST", url, token, data, history)
}

export const putFile = async ({ url, token, data, history }) => {
  return await serviceFile("PUT", url, token, data, history)
}

export const serviceFile = async (method, url, token, data, history) => {
  try {
    const decoded = jwtDecode(token)
    const current_time = Date.now() / 1000

    if (decoded.exp < current_time) {
      toast.error("Seu acesso expirou.")
      return history.replace("/")
    } else {
      try {
        const res = await axios({
          method,
          url: `${process.env.REACT_APP_API_URL}${url}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token
          },
          data
        })
        return res.data
      } catch (error) {
        if (error.response) {
          if (error.response.status === 500) {
            toast.error("Erro interno no servidor. Tente novamente mais tarde.")
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error("Houve um erro ao realizar esta ação")
        }
      }
    }
  } catch (e) {
    toast.error("Token de acesso inválido")
  }
}
