import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 18px;
    text-align: center;
  }
`
