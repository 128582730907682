import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import { toast } from "react-toastify"

import { LoginStore } from "store"
import { LOGIN } from "store/reducers/loginReducer"

export default ({ parent: Parent, child, ...rest }) => {
  const [, dispatch] = useContext(LoginStore)
  const localToken = localStorage.getItem("onboarding_login_token")

  if (!localToken) {
    toast.error("Acesso não autorizado!")
    return <Redirect to="/login" />
  }

  dispatch({ type: LOGIN, payload: localToken })
  return <Parent component={child} {...rest} />
}
