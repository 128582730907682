import React, { useContext, useState } from "react"

import Container from "./styles"
import { Button } from "components/Button"
import { validationMessages } from "utils/messages"
import { Store } from "store"
import { postFile } from "api/api"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { verifyTokenRole } from "config/tokenRouter"
import FileUpload from "components/FileUpload"

export default props => {
  const [state, dispatch] = useContext(Store)
  const { token, docs } = state

  const [file, setFile] = useState(null)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setError("")
    if (docs.type && file) {
      setLoading(true)
      var formData = new FormData()
      formData.append("file", file)
      formData.append("type", docs.type)
      formData.append("position", "back")

      const res = await postFile({
        url: "/document/ocr",
        token: token.value,
        data: formData
      })

      if (res) {
        dispatch({ type: SET_TOKEN, payload: res.token })
        return verifyTokenRole(res.token, props.history)
      }
    } else {
      setError(validationMessages.fillAllFields)
    }

    setLoading(false)
  }

  const handleFileUpload = fileObj => {
    setFile(fileObj)
  }

  return (
    <React.Fragment>
      <Container>
        <h1>Upload de documento - Verso</h1>
        <FileUpload onChange={handleFileUpload} />

        {error && <div className="error">{error}</div>}
        <Button onClick={handleSubmit} loading={loading}>
          Enviar
        </Button>
      </Container>
    </React.Fragment>
  )
}
