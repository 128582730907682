import styled from "styled-components"

export default styled.div`
  .cameraContainer {
    width: 100vw;
    height: 100vh;
  }

  .cameraOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    color: ${props => props.theme.colors.gray};

    &.off {
      display: none;
    }

    span {
      width: 90%;
    }
  }

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.on {
      display: flex;
    }
  }

  .loadingLoader {
    color: ${props => props.theme.colors.gray};
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    transition: width 0.2s ease-in;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .cameraContainer {
      width: 45rem;
      height: 45rem;
      position: relative;
      border-radius: 3rem;
    }

    video {
      border-radius: 3rem;
      /* box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.4); */
    }

    .cameraOverlay {
      border-radius: 3rem;
      font-size: 2.5rem;
    }
  }
`

/*
import styled from "styled-components"

export default styled.div`
  .cameraContainer {
    width: 100vw;
    height: 100vh;
  }

  .cameraOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    font-size: 3rem;
    color: white;
    padding-bottom: 1rem;

    &.off {
      display: none;
    }

    span {
      width: 60%;
    }
  }

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.on {
      display: flex;
    }
  }

  .loadingLoader {
    color: ${props => props.theme.colors.gray};
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    transition: width 0.2s ease-in;
  }

  .maskContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .mask {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .mask:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 80%;
    height: 50%;
    border: 1000px solid rgba(0, 0, 0, 0.5);
    box-sizing: content-box;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .cameraContainer {
      width: 45rem;
      height: 45rem;
      position: relative;
      border-radius: 3rem;
    }

    video {
      border-radius: 3rem;
      box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.4);
    }

    .cameraOverlay {
      border-radius: 3rem;
      font-size: 2.5rem;
    }

    .mask {
      border-radius: 3rem;
    }
    .mask:after {
      top: 53%;
      width: 70%;
      height: 70%;
    }
  }
`
*/
