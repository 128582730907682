import produce from "immer"

export const SET_FIELD = "SET_FIELD"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

const loginReducer = (draft, { type, payload }) => {
  switch (type) {
    case SET_FIELD:
      draft[payload.field] = payload.value
      return
    case LOGIN:
      draft["token"] = payload
      localStorage.setItem("onboarding_login_token", payload)
      return
    case LOGOUT:
      draft["token"] = ""
      localStorage.removeItem("onboarding_login_token")
      return
    default:
      return
  }
}

export default produce(loginReducer)
