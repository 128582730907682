import React, { useState, useContext, useEffect, useCallback } from "react"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Checkbox from "@material-ui/core/Checkbox"
import jwtDecode from "jwt-decode"
import axios from "axios"
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/dynamic"

import Container from "./styles"
import { Button } from "components/Button"
import { Store } from "store"
import { verifyTokenRole } from "config/tokenRouter"
import { roles } from "config/roles"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { validationMessages } from "utils/messages"

export default props => {
  const [{ home }, dispatch] = useContext(Store)
  const [selected, setSelected] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const checkData = useCallback(() => {
    if (!home.cpf) return props.history.replace("/")
  }, [home.cpf, props.history])

  useEffect(() => {
    checkData()
  }, [checkData])

  useEffect(() => {
    if (selected) setError("")
  }, [selected])

  const handleSubmit = async () => {
    setError("")

    if (selected) {
      setLoading(true)
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/new_register`,
        headers: { "Content-Type": "application/json" },
        data: home
      })

      if (res.data) {
        const { token } = res.data
        const { identity } = jwtDecode(token)

        if (identity.role === roles.CAN_VERIFY_PHONE) {
          const myInsistentTimer = setIntervalAsync(async () => {
            const res = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_API_URL}/sms_validate`,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
              }
            })

            if (res) {
              if (res.status === 200) {
                dispatch({ type: SET_TOKEN, payload: res.data.token })
                verifyTokenRole(res.data.token, props.history)
                clearIntervalAsync(myInsistentTimer)
              }
            }
          }, 1000)
        }
      }
    } else {
      setError(validationMessages.mustAgree)
      setLoading(false)
    }
  }

  const toggleSelected = () => setSelected(!selected)

  return (
    <React.Fragment>
      <h1>Termos de uso e Contrato de Serviço</h1>

      <Container>
        <div className="terms">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id velit sit amet quam convallis iaculis vehicula et enim. Proin vel nisl eleifend, aliquam lacus at, molestie arcu. Morbi efficitur eget nunc quis mattis. Proin sit amet ultrices dolor. Fusce non sem ut purus hendrerit
          ullamcorper a ut quam. Aenean luctus euismod purus, ac euismod risus consectetur sit amet. Vestibulum viverra consequat risus a semper. Pellentesque a erat quis nulla ullamcorper blandit vitae ac nulla. Mauris finibus, risus ullamcorper sodales condimentum, urna elit commodo mi, sit amet
          imperdiet dui neque id odio. In id ex sed ex egestas fermentum. Maecenas tincidunt, quam eu semper fermentum, mi erat sodales elit, non molestie lacus ligula vitae lacus. Aenean pulvinar maximus dui, scelerisque iaculis nibh pharetra in. Nam ultricies, est sit amet accumsan gravida, lacus
          quam pulvinar ex, non sodales metus nibh in nibh. Duis ornare nulla nec orci blandit accumsan. Morbi ut ante ac ex finibus vestibulum. Vestibulum id nisl tristique, finibus dolor vel, lacinia sem. Nunc iaculis tortor lorem, a placerat justo eleifend cursus. Etiam porttitor, lorem id
          vehicula venenatis, metus elit scelerisque nunc, eu gravida nibh quam non sem. Nulla in nisl leo. Donec augue lorem, mollis ut ultrices et, sodales id sem. Aliquam euismod mattis elementum. Fusce molestie rhoncus lorem, sed rutrum diam efficitur nec. Duis a odio ultricies, laoreet magna a,
          congue neque. Integer tristique urna sit amet aliquam pharetra. Nulla id nulla id metus molestie luctus a ut nunc. Praesent varius sed sapien sit amet pretium. Pellentesque leo tellus, fermentum eget nunc eu, sagittis placerat mauris. Sed nec nibh fringilla velit posuere ultrices sit amet
          a dui. Quisque non elementum risus. Aliquam urna nibh, vestibulum commodo lacinia non, varius id metus. Aliquam vel dapibus leo, a rutrum erat. Vivamus tristique orci risus, nec consequat arcu consequat in. Cras tincidunt elit et nisl convallis egestas. Duis ornare eros magna, ac
          consectetur odio placerat in. Vivamus et imperdiet orci. Proin imperdiet lectus quis lacinia volutpat. Maecenas non magna felis. In a imperdiet sapien, blandit congue augue. Integer vitae diam id odio tempor dictum vitae a mauris. Nam eleifend risus id porta malesuada. Etiam ut condimentum
          neque, sed tempus elit. Aenean non mi in libero imperdiet suscipit. Mauris sollicitudin convallis massa quis fermentum. Nam id posuere ipsum. Praesent ut auctor augue. In mauris nulla, lacinia non viverra id, facilisis ac est. Nam est augue, commodo vel diam ut, aliquet viverra lorem.
          Vestibulum ut ipsum vel ipsum viverra lobortis. Suspendisse ex orci, rutrum ut est vel, euismod suscipit odio. Donec pretium augue sit amet turpis posuere, et bibendum lectus commodo. Proin laoreet urna sem, sit amet interdum tellus egestas et. Sed ut massa tristique, finibus dui sit amet,
          tincidunt arcu. Pellentesque mattis, tortor sodales iaculis placerat, turpis tortor varius velit, sit amet auctor nibh purus ac augue. Ut vel tellus eu urna laoreet laoreet at sit amet lorem. Suspendisse finibus, enim in placerat semper, libero elit elementum ex, et fermentum dui felis id
          justo. Nullam non nisl leo.
        </div>

        <FormControl className="agreeBox" error={!!error}>
          <FormControlLabel control={<Checkbox checked={selected} onChange={toggleSelected} value={selected} />} label="Estou ciente e aceito" />
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>

        <Button onClick={handleSubmit} loading={loading}>
          Continuar
        </Button>
      </Container>
    </React.Fragment>
  )
}
