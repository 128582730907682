import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import CircularProgress from "@material-ui/core/CircularProgress"
///import Modal from "@material-ui/core/Modal"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Root from "./styles"
import { get } from "api/api"

export const PersonalData = ({ id, token, classname, icon }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [personalData, setPersonalData] = useState(null)
  const [modalStyle] = useState(getModalStyle)

  function rand() {
    return Math.round(Math.random() * 20) - 10
  }

  function getModalStyle() {
    const top = 50 + rand()
    const left = 50 + rand()

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    }
  }

  const handleClick = e => {
    setLoading(true)
    setModalOpen(true)
    fetchData()
  }

  const fetchData = async () => {
    const res = await get({
      url: `/dash/personal_data/${id}`,
      token
    })

    if (res) {
      setPersonalData(res.data)
      setLoading(false)
    } else {
      setModalOpen(false)
    }
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <React.Fragment>
      <span onClick={handleClick}>
        <FontAwesomeIcon icon={icon} className={classname} />
      </span>
      {personalData && (
        <Modal show={modalOpen} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Dados cadastrais</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="show-grid">
              <Col xs={12} md={4}>
                {personalData.profile_pic ? <img width="200px" src={personalData.profile_pic} /> : <img width="200px" src="./images/profile-icon-9.png" />}
              </Col>
              <Col xs={12} md={8}>
                <Row>Nome: {personalData.name}</Row>
                <Row>CPF: {personalData.cpf}</Row>
                <Row>RG: {personalData.rg}</Row>
                <Row>Mãe: {personalData.mother_name}</Row>
                <Row>Idade: {personalData.age}</Row>
                <Row>Nascimento: {personalData.birth_date}</Row>
                <Row>Gênero: {personalData.gender}</Row>
                <Row>Documento: {personalData.doc_type}</Row>
                {personalData.address && (
                  <React.Fragment>
                    <Row>Endereço: {personalData.address.address}</Row>
                    <Row>Número: {personalData.address.number}</Row>
                    <Row>Complemento: {personalData.address.complement}</Row>
                    <Row>Bairro: {personalData.address.neighborhood}</Row>
                    <Row>Cidade: {personalData.address.city}</Row>
                    <Row>Estado: {personalData.address.uf}</Row>
                    <Row>CEP: {personalData.address.zipcode}</Row>
                  </React.Fragment>
                )}
                <Row>Telefone: {personalData.phone.value}</Row>
                <Row>Email: {personalData.email.value}</Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  )
}
