import React, { useReducer } from "react"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MaterialProvider } from "@material-ui/styles"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import defaultTheme, { materialTheme } from "themes/default"
import { Store, initialState } from "store"
import rootReducer from "store/reducers/rootReducer"
import Routes from "config/routes"

import { LoginStore, loginInitialState } from "store"
import loginReducer from "store/reducers/loginReducer"

function App() {
  const store = useReducer(rootReducer, initialState)
  const loginStore = useReducer(loginReducer, loginInitialState)

  return (
    <Store.Provider value={store}>
      <LoginStore.Provider value={loginStore}>
        <ThemeProvider theme={defaultTheme}>
          <MaterialProvider theme={materialTheme}>
            <ToastContainer />
            <Routes />
          </MaterialProvider>
        </ThemeProvider>
      </LoginStore.Provider>
    </Store.Provider>
  )
}

export default App
