import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"

import { Store } from "store"

export default ({ parent: Parent, child, roles, ...rest }) => {
  const [{ token }] = useContext(Store)

  if (!token.value) {
    toast.error("Acesso não autorizado!")
    return <Redirect to="/" />
  }

  const { identity } = jwtDecode(token.value)

  return roles.includes(identity.role) ? <Parent component={child} {...rest} /> : <Redirect to="/" />
}
