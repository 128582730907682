import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;

  .fileContainer {
    width: 100%;
    background-color: ${props => props.theme.colors.lightGray};
    border-radius: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
      color: ${props => props.theme.colors.darkGray};
      font-weight: 600;
      padding: 1.5rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .fileInput {
    display: none;
  }

  .preview {
    border-radius: 0.8rem;
    width: 40%;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.4);
    margin-top: 1.5rem;
  }
`
