import React from "react"
import { Route } from "react-router-dom"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Container from "./styles"
import logo from "assets/inova.svg"
import logoWhite from "assets/inova_white.svg"
import { Store } from "store"

export default ({ component: Component, ...rest }) => {
  const matches = useMediaQuery("(min-width:768px)")

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Container>
          <header>
            {!matches ? <img src={logoWhite} alt="InovaMind" className="logo" /> : <img src={logo} alt="InovaMind" className="logo" />}
            {!matches && <h1>Liveness</h1>}
          </header>
          {matches && <h1>Liveness</h1>}
          <Store.Consumer>{context => <Component {...matchProps} context={context} />}</Store.Consumer>
        </Container>
      )}
    />
  )
}
