import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .logo {
    width: 10rem;
    margin-bottom: 2rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .logo {
      width: 12rem;
    }
  }

  h1 {
    font-size: 3rem;
    margin: 2rem 0%;
    text-align: center;
  }

  h4 {
    font-size: 1.6rem;
    color: ${props => props.theme.colors.gray};
  }
`
