import styled from "styled-components"

export default styled.div`
  width: 100%;
  padding: 1rem;
  overflow: auto;

  table {
    width: 100%;
    font-size: 1.2rem;
    border-collapse: collapse;

    .alignCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    thead {
      tr {
        th {
          padding: 1rem;
          border-bottom: 1px solid ${props => props.theme.colors.gray};

          &:last-child,
          :nth-last-child(2) {
            background-color: ${props => props.theme.colors.lightGray};
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: ${props => props.theme.colors.lightGray};
        }

        &:last-child td {
          border-bottom: 1px solid ${props => props.theme.colors.gray};
        }

        td {
          font-size: 1.2rem;
          padding: 0.8rem;

          &:last-child,
          :nth-last-child(2) {
            background-color: ${props => props.theme.colors.lightGray};
          }

          & > span {
            display: flex;
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          padding: 1rem;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: flex-end;
  }

  .loaderContainer {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noResults {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .loader {
    color: ${props => props.theme.colors.gray};
  }

  .cellIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
  }

  .cellIcon {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    cursor: pointer;
    color: ${props => props.theme.colors.darkGray};
  }

  .green {
    color: ${props => props.theme.colors.green};
  }

  .validated {
    color: ${props => props.theme.colors.green};
    cursor: default;
  }

  .yellow {
    color: ${props => props.theme.colors.yellow};
    cursor: default;
  }

  .notValidated {
    color: ${props => props.theme.colors.red};
    cursor: default;
  }
`
