import { createContext } from "react"

export const Store = createContext()

export const initialState = {
  token: {
    value: ""
  },
  home: {
    cpf: "",
    phone: "",
    email: ""
  },
  terms: {
    agree: false
  },
  address: {
    cep: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    uf: ""
  },
  docs: {
    type: ""
  }
}

export const LoginStore = createContext()

export const loginInitialState = {
  username: "",
  password: "",
  token: ""
}

// "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NjUxMTMzOTQsIm5iZiI6MTU2NTExMzM5NCwianRpIjoiMzk4NTgzMzctMTIwMi00ZDhmLTkyNWEtNzU1MjBmM2UzM2IyIiwiaWRlbnRpdHkiOnsicmVxdWVzdF9pZCI6IjVkNDliYmRkN2M0YmU4YzgwOGIzNmE3MCIsInJvbGUiOiJjYW5fc2VuZF9maXJzdF9saXZlbmVzc19mcmFtZSIsImNlcCI6IjI5MjU1MDAwIn0sImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.6dljsrnhU_Xghtjs7XLws4pFKKdTbUyowqBP6foKeO0"
