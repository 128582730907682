import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .terms {
    font-family: "Muli", sans-serif;
    font-size: 1.8rem;
    max-height: 50rem;
    overflow: auto;
    background-color: white;
    padding: 2rem;
    border-radius: 0.4rem;
  }

  .agreeBox {
    background-color: ${props => props.theme.colors.lightGray};
    padding: 2rem;
    font-weight: 600;
    color: black;
    font-size: 1.6rem;
    width: 100%;
    margin-top: 2rem;
    border-radius: 0.4rem;
  }
`
