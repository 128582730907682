import * as faceapi from "face-api.js"

// Loads models and weights
export async function loadModels() {
  const MODEL_URL = process.env.PUBLIC_URL + "/models"
  await faceapi.loadTinyFaceDetectorModel(MODEL_URL)
  // await faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
  await faceapi.loadFaceRecognitionModel(MODEL_URL)
}

export async function getFullFaceDescription(blob) {
  // tiny_face_detector options
  let scoreThreshold = 0.5
  let inputSize = 512
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  })
  // const useTinyModel = true

  // fetch image api
  let img = await faceapi.fetchImage(blob)

  // detect all faces and generate full description from image
  // including landmark and description for each face
  let fullDesc = await faceapi.detectSingleFace(img, OPTION)
  return fullDesc
}

const maxDescriptorDistance = 0.5
export async function createMatcher(faceProfile) {
  // Create labeled descriptiors of member from profile
  let members = Object.keys(faceProfile)
  let labeledDescriptors = members.map(member => new faceapi.LabeledFaceDescriptors(faceProfile[member].name, faceProfile[member].descriptors.map(descriptor => new Float32Array(descriptor))))

  // Create face matcher (maximum descriptor distance is 0.5)
  let faceMatcher = new faceapi.FaceMatcher(labeledDescriptors, maxDescriptorDistance)

  return faceMatcher
}
