import React from "react"
import Webcam from "react-webcam"
import CircularProgress from "@material-ui/core/CircularProgress"

import Root from "./styles"
import { getFullFaceDescription, loadModels } from "api/face"
import { postFile, putFile } from "api/api"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { verifyTokenRole } from "config/tokenRouter"

export default class Liveness extends React.Component {
  constructor(props) {
    super(props)
    this.webcam = React.createRef()
    this.state = {
      fullDesc: null,
      detected: false,
      percentage: 0,
      imageIndex: 0,
      loading: false,
      detectMessage: "Carregando sistema de detecção facial"
    }
  }

  componentDidMount = () => {
    this.init()
  }

  init = async () => {
    await loadModels()
    await this.setState({ detectMessage: "Aguarde, detectando seu rosto..." })
    this.findFace()
  }

  findFace = async () => {
    if (this.webcam.current.getScreenshot()) {
      await getFullFaceDescription(this.webcam.current.getScreenshot()).then(async fullDesc => {
        if (fullDesc) {
          await this.setState({ detected: true })
          this.postImage()
        } else {
          setTimeout(this.findFace, 1000)
        }
      })
    } else {
      setTimeout(this.findFace, 1000)
    }
  }

  postImage = async () => {
    let file = null
    const screenshot = this.webcam.current.getScreenshot()
    const [{ token }, dispatch] = this.props.context

    let formData = new FormData()

    await fetch(screenshot)
      .then(res => res.blob())
      .then(blob => {
        file = new File([blob], `liveness_${this.state.imageIndex}.png`, { type: "image/png" })
        formData.append("file", file)
      })

    const res = await postFile({
      url: "/liveness",
      token: token.value,
      data: formData
    })

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      await this.setState(oldState => ({ imageIndex: oldState.imageIndex + 1, percentage: oldState.percentage + 50 }))
      setTimeout(() => this.putImage(this.state.imageIndex), 1000)
    }
  }

  putImage = async imageIndex => {
    let file = null
    const screenshot = this.webcam.current.getScreenshot()
    const [{ token }, dispatch] = this.props.context

    let formData = new FormData()

    if (imageIndex < 2) {
      await this.setState(oldState => ({ percentage: oldState.percentage + 50 }))
    } else {
      await this.setState({ loading: true, percentage: 0 })
    }

    await fetch(screenshot)
      .then(res => res.blob())
      .then(blob => {
        file = new File([blob], `liveness_${this.state.imageIndex}.png`, { type: "image/png" })
        formData.append("file", file)
      })

    const res = await putFile({
      url: "/liveness",
      token: token.value,
      data: formData
    })

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      if (imageIndex < 2) {
        await this.setState({ imageIndex: imageIndex + 1 })
        setTimeout(() => this.putImage(this.state.imageIndex), 500)
      } else {
        return verifyTokenRole(res.token, this.props.history)
      }
    }
  }

  render() {
    return (
      <Root>
        <div className="cameraContainer">
          <div className={`cameraOverlay ${this.state.detected && "off"}`}>
            <span>{this.state.detectMessage}</span>
          </div>
          <div className={`loadingOverlay ${this.state.loading && "on"}`}>
            <CircularProgress className="loadingLoader" />
          </div>
          <div className="progress" style={{ width: `${this.state.percentage}%` }} />
          <Webcam audio={false} ref={this.webcam} screenshotFormat="image/jpeg" videoConstraints={{ facingMode: "user" }} />
        </div>
      </Root>
    )
  }
}
